import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

let shareEl;
let parentEl;

export default function share() {
  shareEl = document.getElementById('share');
  if (!shareEl) return;
  parentEl = shareEl.parentElement;
  ScrollTrigger.create({
    trigger: parentEl,
    start: "top top",
    onEnter: () => {
      shareEl.classList.add('share-is-sticky');
    },
    onEnterBack: () => {
      shareEl.classList.remove('share-is-sticky');
    },
  });
}