import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
/* The following plugin is a Club GSAP perk */
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger,SplitText);

const headings = document.querySelectorAll('.js-anim-heading');

function init(heading) {
  const triggerStart = 'top bottom';
  const toggleActions = 'restart none restart none';
  const split = new SplitText(heading);
  const chars = split.chars;
  // The range of y offset values is based on the current number of characters in the design
  const offsets = [0, 10, 15, 5, 10, 0, 0, 0, -10, -10, -5, 0, 10, 0, 0, 0, 15, 0, 5, 0, 0];
  const tl = gsap.timeline({
    scrollTrigger: {
      start: triggerStart,
      trigger: heading,
      toggleActions: toggleActions,
    },
  });

  const start = 'start';
  tl.add(start);

  tl.fromTo(chars, {
    autoAlpha: 0,
  }, {
    autoAlpha: 1,
    duration: 0.4,
    ease: 'none',
  }, start)

  tl.fromTo(chars, {
    yPercent(index) {
      // Maths: we can use the modulus operator to loop back to the start of the offsetPattern if the client adds more letters
      let offset = `+=${offsets[index % offsets.length] + 50}`;
      return offset;
    },
  }, {
    yPercent(index) {
      // Maths: we can use the modulus operator to loop back to the start of the offsetPattern if the client adds more letters
      let offset = offsets[index % offsets.length];
      return offset;
    },
    duration: 3,
    ease: "elastic.out(1,0.2)",
    stagger: 0.02
  }, start);
}

export default function() {
  headings.forEach(heading => init(heading));
}