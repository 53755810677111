import gsap from 'gsap';
const contentSelector = 'js-accord-content';
const activeClass = 'accord-is-active';
const buttons = Array.from(document.querySelectorAll('.js-accord'));

function hide(parentEl) {
  // We might call this function when ajaxing pages so check if menu is defined
  // If not, find it
  let el = parentEl || document.querySelector(`.${activeClass}`);
  if (!el) return;
  el.classList.remove(activeClass);

  const content = el.querySelector(`.${contentSelector}`);
  gsap.to(content, {
    height: 0,
    duration: 0.1,
    ease: 'none'
  });
}

function show(parentEl) {
  const el = parentEl;
  el.classList.add(activeClass);
  const content = el.querySelector(`.${contentSelector}`);
  const tl = gsap.timeline();

  tl.set(content, {height: 'auto'})
  .from(content, {
    height: 0,
    duration: 0.4,
    ease: 'power2.out',
    immediateRender: false
  });
}

function toggle(e) {
  const clicked = e.currentTarget;
  const parentEl = clicked.parentElement;

  if (parentEl.classList.contains(activeClass)) {
    hide(parentEl);
  } else {
    show(parentEl);
  }
}

export default function() {
  buttons.forEach(button => {
    button.addEventListener('click', toggle);
  });
}
