document.documentElement.classList.remove('no-js');
window.addEventListener('keydown', handleFirstTab);

import headingAnim from './modules/heading-anim';
import nav from './modules/nav';
import filter from './modules/filter';
import header from './modules/header';
import carousel from './modules/carousel';
import search from './modules/search';
import anchor from './modules/anchor';
import share from './modules/share';
import accordion from './modules/accordion';
import stickyFeatured from './modules/sticky-featured';
import arrow from './modules/arrow';
import homeCarousel from './modules/home-carousel';
import newsletter from './modules/newsletter';
import skip from './modules/skip';

nav.init();
search.init();
header();
carousel();
filter();
anchor();
share();
accordion();
newsletter();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('load', function() {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
    window.scroll(0, 0);
  }
  skip();
  arrow();
  headingAnim();
  homeCarousel();
  stickyFeatured();
  document.body.classList.remove('page-is-loading');
});