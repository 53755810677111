// https://verbb.io/craft-plugins/formie/docs/developers/javascript-api#javaScript-events
let formEl = document.querySelector('#newsletter-form');
let successTimeout;

function addSuccessClass() {
  formEl.classList.add('form-submit-success');
  successTimeout = setTimeout(() => {
    formEl.classList.remove('form-submit-success');
  }, 3000);
}

export default function() {
  if (!formEl) return;
  
  formEl.addEventListener('onBeforeFormieSubmit', (e) => {
    if (successTimeout) clearTimeout(successTimeout);
    formEl.classList.remove('form-submit-success');
  });

  formEl.addEventListener('onAfterFormieSubmit', () => {
    addSuccessClass();
  });
}