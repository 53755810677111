import { gsap } from "gsap";    
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const skip = document.getElementById('skip-to-content');

function handleClick(e) {
  e.preventDefault();
  const link = e.target.closest('a');
  const selector = link.getAttribute('href');
  const match = document.querySelector(selector);
  match.focus({preventScroll: true});
}

export default function() {
  skip.addEventListener('click', handleClick);
}