const stickyLeft = Array.from(document.querySelectorAll('.js-sticky-featured-left'));
let timeout;

function initResize() {
  // Listen for resize events
  window.addEventListener('resize', function() {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      init();
    });
  }, false);
}

function calcPad(el) {
  const heading = el.querySelector('.js-sticky-featured-heading');
  if (!heading) return;
  const headingHeight = heading.offsetHeight;
  const right = el.parentElement.querySelector('.js-sticky-featured-right');
  right.style.paddingTop = `${headingHeight}px`;
}

function init() {
  stickyLeft.forEach(el => calcPad(el));
}

export default function() {
  initResize();
  init();
}