import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const button = document.getElementById('nav-button');
const body = document.body;
const activeClass = 'nav-is-active';
const navEl = document.getElementById('nav');
const menuIcon = document.getElementById('nav-menu-icon');
const closeIcon = document.getElementById('nav-close-icon');
const navEls = document.querySelectorAll('.js-nav-el-anim');
const socialEls = document.querySelectorAll('.js-social-el-anim');
const badge = document.getElementById('nav-badge');

const showTl = gsap.timeline({paused: true, id: 'show-nav'});
showTl.add('start')
.to(navEl, {
  display: 'block',
  duration: 0.1,
  opacity: 1
}, 'start')
.from(badge, {
  duration: 0.2,
  scale: 0.8,
  opacity: 0,
  rotate: 90,
  ease: 'back.out(1.1)'
}, 'start')
.from(navEls, {
  stagger: 0.02,
  duration: 0.5,
  y: 10,
  opacity: 0,
  ease: 'back.out(1.1)'
}, 'start')
.from(socialEls, {
  stagger: 0.05,
  duration: 0.5,
  y: 10,
  opacity: 0,
  ease: 'elastic.out(1, 0.3)',
}, 'start')
.fromTo(closeIcon.children, {
  y: -5,
  x: (index) => {
    return index % 2 ? 5 : -5;
  },
  opacity: 0
}, {
  y: 0,
  x: 0,
  opacity: 1,
  duration: 0.5,
  ease: 'elastic.out(1, 0.3)',
  immediateRender: false
}, 'start');

const hideTl = gsap.timeline({paused: true});
hideTl.add('start')
.to(navEl, {
  duration: 0.1,
  opacity: 0,
  display: 'none',
  ease: 'none'
}, 'start')
.fromTo(menuIcon.children, {
  scaleX(index) {
    if (index === 0 || index === 2) {
      return 0.5;
    }
  },
  transformOrigin(index) {
    if (index === 0) {
      return 'left center';
    } else if (index === 2) {
      return 'right center';
    }
  },
  opacity: 0,
}, {
  duration: 0.5,
  scaleX: 1,
  ease: 'elastic.out(1, 0.3)',
  opacity: 1,
  immediateRender: false
}, 'start')
.to(closeIcon.children, {
  y: -5,
  x: (index) => {
    return index % 2 ? 5 : -5;
  },
  duration: 0.1,
  ease: 'none',
  opacity: 0
}, 'start');

function hide() {
  button.setAttribute('aria-expanded', false);
  ScrollTrigger.normalizeScroll(true);
  body.classList.remove(activeClass);
  button.blur();
  hideTl.restart();
}

function show() {
  button.setAttribute('aria-expanded', true);
  ScrollTrigger.normalizeScroll(false);
  body.classList.add(activeClass);
  showTl.restart();
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    hide();
  } else {
    show();
  }
}

function hover() {
  gsap.to(menuIcon.children, {
    duration: 0.2,
    scaleX(index) {
      if (index === 0 || index === 2) {
        return 0.5;
      }
    },
    transformOrigin(index) {
      if (index === 0) {
        return 'left center';
      } else if (index === 2) {
        return 'right center';
      }
    },
    ease: 'power2.out'
  });
}

function hoverOff() {
  gsap.to(menuIcon.children, {
    duration: 0.1,
    scaleX: 1,
    ease: 'none'
  });
}

const nav = {
  hide: hide,
  init() {
    button.addEventListener('click', toggle);
    button.addEventListener('mouseenter', hover);
    button.addEventListener('mouseleave', hoverOff);

    document.addEventListener('keydown', (e) => {
      const navIsOpen = document.querySelector(`.${activeClass}`);
      if (e.code === 'Escape' && navIsOpen) {
        hide();
      }
    });
  }
}

export default nav;
