import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
/* The following plugin is a Club GSAP perk */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(ScrollTrigger,MotionPathPlugin,DrawSVGPlugin);

function init(arrow) {
  const path = arrow.querySelector('.js-arrow-path');
  const arrowHead = arrow.querySelector('.js-arrow-head');
  const percentOverflow = (100 * arrow.clientWidth) / arrow.scrollWidth;
  const decimalOverflow = arrow.clientWidth / arrow.scrollWidth;
  const align = arrow.dataset.align;
  const autoRotate = align === 'left' ? -15 : -45;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: arrow,
      start: 'clamp(top bottom)',
      end: 'top 10%',
      scrub: true
    },
  });
  tl.add('start');
  tl.fromTo([arrowHead, path], {
    autoAlpha: 0,
  }, {
    duration: 0.01,
    autoAlpha: 1
  }, 'start');
  tl.from(path, { 
    drawSVG: `${100 - percentOverflow}% 0%`, 
    duration: 1,
    ease: 'none',
  },'start');
  tl.from(arrowHead, {
    motionPath: {
      path: path,
      align: path,
      alignOrigin: [0.5, 0.5],
      // For some reason motionPath seems to read the path backwards
      start: 1,
      end: 1 - decimalOverflow,
      autoRotate: autoRotate,
    },
    duration: 1,
    ease: 'none'
  }, 'start');
}

export default function() {
  const arrows = document.querySelectorAll('.js-home-arrow');
  arrows.forEach(arrow => init(arrow));
}