import axios from 'axios';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const body = document.body;
const resetBtn = document.getElementById('reset-search-filters');
const searchEl = document.getElementById('search');
const searchIcon = document.getElementById('search-icon');
const closeIcon = document.getElementById('search-close-icon');
const searchBtn = document.getElementById('search-btn' );
const closeBtn = document.getElementById('search-close-btn' );
const form = document.getElementById('search-form');
const searchInput = document.getElementById('search-input');
const filters = document.querySelectorAll('.js-search-filter');
const resetClass = 'filter-reset-active';
const activeClass = 'search-is-active';
const loadingClass = 'search-is-loading';
const resultClass = 'js-search-result';

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('resize', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      calcOffset();
  	});
  }, false);
}

function hide() {
  ScrollTrigger.normalizeScroll(true);
  body.classList.remove(activeClass);
  const tl = gsap.timeline();
  tl.add('start')
  .to(searchEl, {
    duration: 0.1,
    opacity: 0,
    display: 'none',
    ease: 'none'
  }, 'start')
  .to(searchIcon, {
    duration: 0.1,
    opacity: 1,
  }, 'start')
  .to(closeIcon.children, {
    y: -5,
    x: (index) => {
      return index % 2 ? 5 : -5;
    },
    duration: 0.1,
    ease: 'none',
    opacity: 0
  }, 'start');
}

function show() {
  // https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.normalizeScroll()
  // We set this to true on the homepage to help with Safari's jankiness.
  // BUT you need to set it to back false to be able to scroll the modal, because it 'hijacks' native scrolling and switches it to the JS thread.
  ScrollTrigger.normalizeScroll(false);
  body.classList.add(activeClass);
  const tl = gsap.timeline();
  tl.add('start')
  .to(searchEl, {
    display: 'block',
    duration: 0.1,
    opacity: 1,
    onComplete: function() {
      searchInput.focus();
    }
  }, 'start')
  .to(searchIcon, {
    duration: 0.1,
    opacity: 0,
    ease: 'none'
  }, 'start')
  .fromTo(closeIcon.children, {
    y: -5,
    x: (index, el) => {
      return index % 2 ? 5 : -5;
    },
    rotate: (index) => {
      return index % 2 ? 45 : -45;
    },
    opacity: 0
  }, {
    y: 0,
    x: 0,
    opacity: 1,
    duration: 0.5,
    ease: 'elastic.out(1, 0.3)',
  }, 'start');
}

function ajaxSearch() {
  const searchContainer = document.getElementById('ajax-search-container');
  const form = document.getElementById('search-form');
  const filters = Array.from(form.querySelectorAll('[name="filters[]"]:checked'),function(el){ return el.value; }).join('|');
  if (searchInput.value.length === 0) {
    searchContainer.innerHTML = '';
    return;
  }

  body.classList.add(loadingClass);

  // const keywordString = `q=${searchInput.value}`;
  // const filterString = `?filters=${filters}`;
  // const url = `${filterString}&${keywordString}`;
  const formUrl = form.getAttribute('action');
  const params = new URLSearchParams();
  params.append('filters', filters);
  params.append('q', searchInput.value);

  axios({ 
    url: formUrl,
    params: params
  })
  .then((res) => {
    const resDoc = document.createRange().createContextualFragment(res.data);
    const resultsEl = resDoc.getElementById('search-results');
    const innerHTML = resultsEl.innerHTML;
    searchContainer.innerHTML = innerHTML;
    body.classList.remove(loadingClass);
    gsap.from(`.${resultClass}`, {
      y: -10,
      opacity: 0,
      stagger: 0.1
    })
  })
  .catch((thrown) => {
    console.log(thrown);
  })
}

function calcOffset() {
  // Get header height for top offset
  const header = document.getElementById('header');
  const height = header.offsetHeight;
  const searchMain = document.getElementById('search-container');
  searchMain.style.top = `${height}px`;
}

function reset() {
  resetBtn.classList.add(resetClass);
  filters.forEach(filter => filter.checked = false);
  ajaxSearch();
}

const search = {
  hideSearch: hide,

  init() {
    calcOffset();
    initResizeListener();
    searchBtn.addEventListener('click', show);
    closeBtn.addEventListener('click', hide);
    resetBtn.addEventListener('click', reset);

    document.addEventListener('keydown', (e) => {
      const searchIsOpen = document.querySelector(`.${activeClass}`);
      if (e.code === 'Escape' && searchIsOpen) {
        hide();
      }
    });

    document.addEventListener('change', function(e) {
      const target = e.target;
      const filter = target.closest('.js-search-filter');
      if (filter) {
        resetBtn.classList.remove(resetClass);
        e.stopPropagation();
        ajaxSearch();
      }
    });

    form.addEventListener('submit', function(e) {
      e.preventDefault()
      ajaxSearch();
    });
  }
}

export default search;
