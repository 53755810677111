import Glide from '@glidejs/glide';

function init(el) {
  const type = el.dataset.type;
  let options;

  if (type === 'circle') {
    options = {
      type: 'carousel',
      focusAt: 'center',
      startAt: 2,
      perView: 3,
      gap: 0,
      peek: {
        before: 100,
        after: 100
      },
      breakpoints: {
        1280: {
          perView: 1,
          peek: {
            before: 300,
            after: 300
          },
        },
        968: {
          perView: 1,
          peek: {
            before: 200,
            after: 200
          },
        },
        768: {
          perView: 1,
          peek: {
            before: 0,
            after: 0
          },
        }
      }
    }
  } else {
    options = {
      type: 'carousel',
      perView: 2,
      gap: 40,
      peek: {
        before: 0,
        after: 100
      },
      breakpoints: {
        600: {
          perView: 1,
          peek: {
            before: 0,
            after: 0
          },
        }
      }
    }
  }

  const carousel = new Glide(el, options).mount();
}

export default function() {
  const carousels = Array.from(document.querySelectorAll('.js-module-carousel'));
  if (!carousels.length) {
    return;
  }
  carousels.forEach(carousel => init(carousel));
}
