import axios from 'axios';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const newElAnimateClass = 'js-to-animate';

function ajax(options) {
  const { url, append = false, updateHistory = true } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');
  axios.get(url).then((res) => {
    // Parse the returned HTML
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    // If we're appending rather than replacing, add class to new cards in so we only animate those
    const newAnimateEls = newContainer.querySelectorAll('.js-ajax-animate');
    newAnimateEls.forEach(card => card.classList.add(newElAnimateClass));
    // Get innerHTML
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.getElementById('ajax-cards');
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;

    if (append) {
      const prevLoadMore = document.getElementById('load-more-container');
      if (prevLoadMore) {
        prevLoadMore.remove();  
      }
      ajaxContainer.insertAdjacentHTML('beforeend', newContent);
    } else {
      ajaxContainer.innerHTML = newContent;
    }

    if (updateHistory) {
      history.pushState({
        type: 'filter',
        url: url,
        filterValues: storeFilterValues()
      }, '', url);  
    }

    ajaxContainer.style.height = `auto`;

    const els = document.querySelectorAll(`.${newElAnimateClass}`);
    gsap.from(els, {
      opacity: 0,
      y: 10,
      duration: 0.5,
      delay: 0.2,
      stagger: 0.1,
    });
    // Remove newCardClass so you don't animate the same ones again if you load more
    els.forEach(el => el.classList.remove(newElAnimateClass));
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function updateFilters() {
  const resetButton = document.getElementById('reset-filters');
  
  const checked = Array.from(document.querySelectorAll('.js-filter:checked'));
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  if (checked.length) {
    const qString = checked.reduce((p, c, i) => {
      return p + c.value + `${i < checked.length - 1 && checked.length > 1 ? ',' : ''}`;
    }, '?filters=');
    url = url + qString;
    resetButton.classList.remove('filter-reset-active');
  } else {
    resetButton.classList.add('filter-reset-active');
  }
  ajax({url: url});
}

function resetFilters(button) {
  const filters = Array.from(document.querySelectorAll('.js-filter'));
  filters.forEach((filter) => filter.checked = false);
  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  button.classList.add('filter-reset-active');
  ajax({url: url});
}

function storeFilterValues() {
  let filterValues = {};
  const filterItems = document.querySelectorAll('.js-filter');
  filterItems.forEach(function(item) {
    filterValues[item.id] = item.checked;
  });
  return JSON.stringify(filterValues);
}

function restoreFilters(event) {
  const storedValues = JSON.parse(event.state.filterValues);
  const keys = Object.keys(storedValues);
  if (keys.length) {
    keys.forEach(key => {
      const filter = document.getElementById(key);
      filter.checked = storedValues[key];
    });
  }
}

export default function() {
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const loadMore = clicked.closest('#load-more');
    const resetButton = clicked.closest('#reset-filters');
    if (loadMore) {
      e.preventDefault();
      const url = decodeURIComponent(loadMore.href);
      ajax({
        url: url,
        append: true,
        filterValues: storeFilterValues()
      });
    } else if (resetButton) {
      resetFilters(resetButton);
    }
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      e.stopPropagation();
      updateFilters();
    }
  });

  // Add initial state if initial page load is filters page
  const filterItems = document.querySelectorAll('.js-filter');
  if (filterItems.length) {
    if (typeof history.replaceState !== 'undefined') {
      // Store an initial state, so the back button works properly when returning
      // to the page you started on:
      history.replaceState({
        type: 'filter',
        url: window.location.href,
        filterValues: storeFilterValues()
      }, '', window.location.href);  
    }
  }

  // Allow the back button to work nicely with AJAX-loaded content:
  window.addEventListener('popstate', function(event){
    // If the previous page was AJAX loaded, reload via AJAX
    if (event.state.type === 'filter') {
      restoreFilters(event);
      // but don't push this onto the history, because we're going backwards, not forwards
      ajax({
        url: event.state.url,
        append: false,
        updateHistory: false
      });
    }
  });
}
