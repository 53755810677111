import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

export default function() {
  const anchors = Array.from(document.querySelectorAll('a[href^="#"]'));
  anchors.forEach(function(anchor) {
    const href = anchor.getAttribute('href');
    if (href === '#' || !href.includes('#')) return;
    const match = document.querySelector(href);
    if (!match) return;
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      gsap.to(window, {
        duration: 1, 
        scrollTo: {
          y: match,
        },
        ease: 'power2.inOut'
      });
    });
  });
}
